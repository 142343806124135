var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Header', {
    attrs: {
      "developments": _vm.developments
    },
    on: {
      "searchInput": _vm.changeSearch,
      "changeTab": function ($event) {
        _vm.tab = $event;
        _vm.setupDevelopments($event);
      }
    }
  }), _vm.developments ? _c('Content', {
    key: _vm.updater,
    attrs: {
      "tab": _vm.tab,
      "active-view": _vm.activeDevelopmentsView,
      "developments": _vm.developments,
      "search": _vm.searchInput
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }