<template>
  <div
    class="property-grid-card-content"
    @click="$emit('cardClick', development._id)"
  >
    <div class="img-wrapper" style="">
      <img
        v-if="development.images && development.images.length"
        class="image-available"
        :src="development.images[0].name"
        alt=""
      />
      <div
        v-if="!development.images || !development.images.length"
        class="empty-image"
      ></div>
    </div>
    <div>
      <v-row
        v-if="development.projectName"
        class="content-bold"
        style="margin-top: 8px"
      >
        <h5>{{ development.projectName }}</h5>
      </v-row>
    </div>

    <div v-if="development.address" style="margin-top: 0px">
      <v-row style="margin-top: 0px" class="user-field-row">
        <p class="field-value content-small" style="width: 100%">
          {{ development.address.address }}
        </p>
      </v-row>
    </div>
  </div>
</template>
<script>
//import propertyApi from "@/http/property";
export default {
  components: {},
  props: {
    development: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    developmentItem: null,
  }),
  created() {
    this.developmentItem = this.development;
    //this.countBuildingContracts();
  },
  mounted() {},
  computed: {},
  methods: {
    /*  countBuildingContracts() {
      this.propertyItem.tenantsCounter = 0;
      this.propertyItem.buildings.forEach((building) => {
        if (building.units.length) {
          building.units.forEach((unit) => {
            if (unit.contract && unit.contract.length) {
              unit.contract.forEach((contract) => {
                if (contract.length !== 0 && contract.statusCode !== 0) {
                  this.propertyItem.tenantsCounter++;
                }
              });
            }
          });
        }
      });
    }, */
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/css/DealCard";

.property-grid-card-content {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  width: 256px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03),
    0 0 1px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}
.img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 224px;
  height: 156px;
  overflow: hidden;
  border-radius: 8px;
  img {
    width: auto;
    border-radius: 8px;
    height: 100%;
  }
}
.empty-image {
  height: 156px;
  width: 224px;
  border-radius: 8px;
  background-color: #f4f5f7;
}
.image-available {
  border-radius: 8px;
}
.field-name {
  width: 128px;
}
.field-value {
  width: 94px;
}
</style>
