<template>
  <div class="content">
    <div
      style="margin-top: 32px; position: absolute; top: 30%; left: 50%"
      v-if="!usableDevelopments"
      class="loader"
    />
    <div v-if="usableDevelopments.length < 1" class="no-results-wrapper">
      <p v-if="!search && tab !== 'archived'" class="heading-geo32 no-results">
        {{ $t("AddADevelopmentToStart") }}
      </p>
      <p v-if="search || tab == 'archived'" class="heading-geo32 no-results">
        {{ $t("NoResultsFound") }}
      </p>
    </div>
    <GridContent
      v-if="activeView === 'GridView' && usableDevelopments.length"
      :taskUpdater="taskUpdater"
      :developments="usableDevelopments"
      @cardClick="cardClick"
    />
    <!-- 
    <ListContent
      v-if="activeView === 'ListView' && usableDevelopments.length"
      :taskUpdater="taskUpdater"
      :developments="usableDevelopments"
      @cardClick="cardClick"
    /> -->
  </div>
</template>

<script>
//import ListContent from "@/components/PropertyDeveloper/ListContent";
import GridContent from "@/components/PropertyDeveloper/GridContent";
//import axios from "axios";
//import { mapActions } from "vuex";

export default {
  name: "Content",
  components: {
    //ListContent,
    GridContent,
  },
  props: ["activeView", "developments", "search", "tab"],
  data() {
    return {
      originalArr: null,
      usableDevelopments: null,
      lastMovedDevelopment: null,
      taskUpdater: 0,
    };
  },
  watch: {
    handler() {
      this.$forceUpdate();
    },
    search() {
      this.originalArr = [...this.developments];
      if (this.search) {
        let searchValue = this.search.toLowerCase();
        let tempItemsTitle = this.originalArr.filter(
          (item) => item.projectName.toLowerCase().indexOf(searchValue) > -1
        );
        this.usableDevelopments = [...tempItemsTitle];
      } else {
        this.usableDevelopments = this.originalArr;
      }
    },
  },
  created() {
    this.initData();
  },
  mounted() {
    if (this.developments != null) this.initData();
  },
  methods: {
    //...mapActions(["changeSidepanelStateDevelopment"]),
    /*    openDevelopment() {
      this.$router.push({
        name: "DevelopmentDetail",
        params: { id: this.lastMovedDevelopment._id },
      });
    }, */
    initData() {
      this.usableDevelopments = [...this.developments];
      this.originalArr = [...this.developments];
    },

    cardClick(developmentId) {
      //this.changeSidepanelStateDevelopment();
      this.$router.push({
        name: "DevelopmentDetail",
        params: { id: developmentId },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.no-results-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 48px 48px 0px;
  flex-grow: 0;
  margin: 24px 112px;
}
</style>
