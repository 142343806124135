<template>
  <div id="projects-kanban-content" class="deal-grid-content">
      <GridCard
        v-for="development in developments"
        :key="development._id"
        :development="development"
        :id="development._id"
        @cardClick="(developmentId) => $emit('cardClick', developmentId)"
        style="margin-top: 12px"
      />
    </div>
</template>
<script>
import GridCard from "./GridCard.vue";
//import { mapActions } from "vuex";

export default {
  name: "GridContent",
  components: {
    GridCard,
  },
  props: ["taskUpdater", "developments"],
  data() {
    return {};
  },
  mounted() {},
  created() {
  },
  watch: {},
  methods: {
    //...mapActions(["changeSidepanelStatedevelopments", "changeSidepanelStateDevelopment"]),
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/DealCard";

.deal-grid-content {
  display: flex;
  background-color: #f4f5f7;
  flex-wrap: wrap;
  gap: 16px;
  padding: 24px 48px 72px 112px;
}

.deal-pipeline {
  min-width: 296px;
  height: 80%;
}

.pipeline-sub-info {
  color: #939597;
  gap: 8px;
  margin-top: 2px;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #939597;
  border-radius: 50%;
  align-self: center;
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
  min-height: 100%;
  margin-top: 12px;
}
</style>
