var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "property-grid-card-content",
    on: {
      "click": function ($event) {
        return _vm.$emit('cardClick', _vm.development._id);
      }
    }
  }, [_c('div', {
    staticClass: "img-wrapper"
  }, [_vm.development.images && _vm.development.images.length ? _c('img', {
    staticClass: "image-available",
    attrs: {
      "src": _vm.development.images[0].name,
      "alt": ""
    }
  }) : _vm._e(), !_vm.development.images || !_vm.development.images.length ? _c('div', {
    staticClass: "empty-image"
  }) : _vm._e()]), _c('div', [_vm.development.projectName ? _c('v-row', {
    staticClass: "content-bold",
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.development.projectName))])]) : _vm._e()], 1), _vm.development.address ? _c('div', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-row', {
    staticClass: "user-field-row",
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('p', {
    staticClass: "field-value content-small",
    staticStyle: {
      "width": "100%"
    }
  }, [_vm._v(" " + _vm._s(_vm.development.address.address) + " ")])])], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }