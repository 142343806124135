import axios from "axios";
let controller = new AbortController();
let signal = controller.signal;
export default {
  async getDevelopments(status) {
    return (
      await axios.get(`/api/developments/${status}`, {
        signal: signal,
      })
    ).data;
  },
};
