var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [!_vm.usableDevelopments ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "margin-top": "32px",
      "position": "absolute",
      "top": "30%",
      "left": "50%"
    }
  }) : _vm._e(), _vm.usableDevelopments.length < 1 ? _c('div', {
    staticClass: "no-results-wrapper"
  }, [!_vm.search && _vm.tab !== 'archived' ? _c('p', {
    staticClass: "heading-geo32 no-results"
  }, [_vm._v(" " + _vm._s(_vm.$t("AddADevelopmentToStart")) + " ")]) : _vm._e(), _vm.search || _vm.tab == 'archived' ? _c('p', {
    staticClass: "heading-geo32 no-results"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoResultsFound")) + " ")]) : _vm._e()]) : _vm._e(), _vm.activeView === 'GridView' && _vm.usableDevelopments.length ? _c('GridContent', {
    attrs: {
      "taskUpdater": _vm.taskUpdater,
      "developments": _vm.usableDevelopments
    },
    on: {
      "cardClick": _vm.cardClick
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }