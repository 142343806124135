<template>
  <div class="header" style="background-color: white">
    <v-row class="title-row">
      <h3 class="header-title">{{ $t("DevelopmentProjects") }}</h3>
      <div class="beta">
        <p>{{ $t("Beta").toUpperCase() }}</p>
      </div>
      <AddNewButton />
    </v-row>
    <div class="tab-con">
      <div
        @click="changeTab('active')"
        class="tab"
        :class="
          tab === 'active'
            ? 'content-small-semibold tab-selected'
            : 'content-small'
        "
      >
        {{ $t("ActiveProjects") }}
      </div>
      <div
        @click="changeTab('archived')"
        class="tab"
        :class="
          tab === 'archived'
            ? 'content-small-semibold tab-selected'
            : 'content-small'
        "
      >
        {{ $t("Archive") }}
      </div>
    </div>
    <HeaderToolbar
      :module="'developments-kanban'"
      style="justify-content: space-between"
    >
      <v-row>
        <button
          @click="changeSidepanelStateDevelopments()"
          class="outlined-small-button"
          style="background-color: black !important"
        >
          <img
            src="../../assets/images/plusSign.svg"
            style="
              filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
                brightness(103%) contrast(102%);
            "
            alt=""
          />
          <p
            style="
              filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
                brightness(103%) contrast(102%);
            "
          >
            {{ $t("AddProject") }}
          </p>
        </button>

        <img class="point-separator" src="../../assets/images/•.svg" alt="" />

        <div style="margin-left: 0px">
          <v-text-field
            autocomplete="off"
            outlined
            v-model="search"
            hide-details
            :id="'search-bar'"
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('Search') + '...'"
            style="background: white"
          >
          </v-text-field>
        </div>
      </v-row>

      <span style="display: flex; gap: 8px">
        <button
          class="outlined-small-button d-inline-flex"
          @click="setActiveDevelopmentsView('GridView')"
          :style="
            activeDevelopmentsView === 'GridView'
              ? 'background-color: #F4F5F7 !important;'
              : null
          "
        >
          <img
            src="../../assets/images/grid_view2.svg"
            alt=""
            class="view-button"
          />
          <p class="button-text">
            {{ $t("Grid") }} {{ $t("View").toLowerCase() }}
          </p>
        </button>

        <!--    <button
          class="outlined-small-button d-inline-flex"
          @click="setActiveDevelopmentsView('ListView')"
          :style="
            activeDevelopmentsView === 'ListView'
              ? 'background-color: #F4F5F7 !important;'
              : null
          "
        >
          <img
            src="../../assets/images/list_view.svg"
            alt=""
            class="view-button"
          />
          <p class="button-text">
            {{ $t("List") }} {{ $t("View").toLowerCase() }}
          </p>
        </button> -->
      </span>
    </HeaderToolbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddNewButton from "@/components/common/AddNew";
import HeaderToolbar from "@/components/common/HeaderToolbar";

export default {
  name: "Header",
  components: { AddNewButton, HeaderToolbar },
  props: ["developments"],
  data() {
    return {
      view: "Kanban",
      search: "",
      tab: "active",
    };
  },
  created() {},
  mounted() {},
  watch: {
    search: function (val) {
      this.$emit("searchInput", val);
    },
  },
  computed: {
    ...mapGetters(["activeDevelopmentsView"]),
  },
  methods: {
    ...mapActions([
      "changeSidepanelStateDevelopments",
      "changeSidepanelStateDevelopment",
      "setActiveDevelopmentsView",
    ]),
    changeTab(tab) {
      this.tab = tab;
      this.$emit("changeTab", tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.view-button {
  filter: brightness(0);
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.button-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;
}

.v-btn {
  margin-left: 12px !important;
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
}
.tab-con {
  display: flex;
  gap: 24px;
  padding-left: 104px;
}
.v-list-item {
  .v-list-item--link {
    .theme--light div {
      display: flex;
    }
  }
}

.col {
  padding: 0;
}

.left-row {
  justify-content: flex-start;

  .v-select {
    max-width: 250px;
  }

  .sector-type {
    margin-right: 42px;
  }
}

.right-row {
  justify-content: flex-end;

  .autocomplete {
    max-width: 300px;
  }

  .icon-button {
    border: 1px solid #e4e4e4;
    height: 40px;
    width: 40px;
    display: flex;
    outline: none;
    padding: 16px;
    align-items: center;
    user-select: none;
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 8px;

    justify-content: center;
  }
}

.icon-button-active {
  background-color: #f4f5f7;
}

::v-deep .v-text-field .v-input__control .v-input__slot {
  min-height: 34px !important;
  max-height: 34px !important;
}

::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 5.5px;
}

::v-deep .v-icon.v-icon {
  font-size: 18px;
}

::v-deep .theme--light.v-icon {
  color: black;
}

::v-deep .v-text-field__slot input {
  font: revert !important;
}
.title-row {
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}
.beta {
  box-sizing: border-box;
  cursor: default;
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 12px;
  gap: 8px;
  height: 28px;

  border: 1px solid #f81ca0;
  border-radius: 6px;
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;
    letter-spacing: 0.1em;

    /* magenta */

    color: #f81ca0;
  }
}
.tab-selected {
  border-bottom: 2px solid black;
}
.tab {
  padding-bottom: 24px;
  cursor: pointer;
}
</style>
