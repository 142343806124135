<template>
  <div>
    <Header
      :developments="developments"
      @searchInput="changeSearch"
      @changeTab="
        tab = $event;
        setupDevelopments($event);
      "
    />
    <Content
      :key="updater"
      :tab="tab"
      v-if="developments"
      :active-view="activeDevelopmentsView"
      :developments="developments"
      :search="searchInput"
    />
  </div>
</template>

<script>
import Header from "@/components/PropertyDeveloper/Header.vue";
import Content from "@/components/PropertyDeveloper/Content.vue";
import { mapGetters, mapActions } from "vuex";
//import axios from "axios";
import developerApi from "@/http/developer";

export default {
  name: "PropertyDeveloper",
  components: {
    Header,
    Content,
  },
  data() {
    return {
      searchInput: "",
      developments: null,
      updater: 0,
      tab: "active",
      //propertyOwnerUpdated: 0,
      //addUserPanelOpen: false,
      //userSidepanelClosed: true,
    };
  },
  mounted() {},
  async created() {
    this.setupDevelopments("active");
  },
  computed: {
    ...mapGetters(["filteredUsers", "activeDevelopmentsView"]),
  },
  methods: {
    ...mapActions([
      "changeSidepanelStateDevelopments",
      "changeSidepanelStateDevelopment",
      "cancelProspectsEditMode",
      "cancelProspectsAddRow",
    ]),
    async setupDevelopments(tab) {
      this.cancelProspectsEditMode();
      this.cancelProspectsAddRow();
      this.changeSidepanelStateDevelopment(1);
      this.changeSidepanelStateDevelopments(0);
      if (!this.$route.params.id) {
        this.changeSidepanelStateDevelopment(0);
        window.history.replaceState(
          {},
          document.title,
          "/property-development"
        );
      }
      await this.getDevelopments(tab);
      this.updater++;
    },
    async getDevelopments(tab) {
      this.developments = await developerApi.getDevelopments(tab);
    },
    changeSearch(val) {
      this.searchInput = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
