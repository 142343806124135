var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header",
    staticStyle: {
      "background-color": "white"
    }
  }, [_c('v-row', {
    staticClass: "title-row"
  }, [_c('h3', {
    staticClass: "header-title"
  }, [_vm._v(_vm._s(_vm.$t("DevelopmentProjects")))]), _c('div', {
    staticClass: "beta"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("Beta").toUpperCase()))])]), _c('AddNewButton')], 1), _c('div', {
    staticClass: "tab-con"
  }, [_c('div', {
    staticClass: "tab",
    class: _vm.tab === 'active' ? 'content-small-semibold tab-selected' : 'content-small',
    on: {
      "click": function ($event) {
        return _vm.changeTab('active');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ActiveProjects")) + " ")]), _c('div', {
    staticClass: "tab",
    class: _vm.tab === 'archived' ? 'content-small-semibold tab-selected' : 'content-small',
    on: {
      "click": function ($event) {
        return _vm.changeTab('archived');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Archive")) + " ")])]), _c('HeaderToolbar', {
    staticStyle: {
      "justify-content": "space-between"
    },
    attrs: {
      "module": 'developments-kanban'
    }
  }, [_c('v-row', [_c('button', {
    staticClass: "outlined-small-button",
    staticStyle: {
      "background-color": "black !important"
    },
    on: {
      "click": function ($event) {
        return _vm.changeSidepanelStateDevelopments();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)"
    },
    attrs: {
      "src": require("../../assets/images/plusSign.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticStyle: {
      "filter": "invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddProject")) + " ")])]), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("../../assets/images/•.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticStyle: {
      "margin-left": "0px"
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "background": "white"
    },
    attrs: {
      "autocomplete": "off",
      "outlined": "",
      "hide-details": "",
      "id": 'search-bar',
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('Search') + '...'
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)]), _c('span', {
    staticStyle: {
      "display": "flex",
      "gap": "8px"
    }
  }, [_c('button', {
    staticClass: "outlined-small-button d-inline-flex",
    style: _vm.activeDevelopmentsView === 'GridView' ? 'background-color: #F4F5F7 !important;' : null,
    on: {
      "click": function ($event) {
        return _vm.setActiveDevelopmentsView('GridView');
      }
    }
  }, [_c('img', {
    staticClass: "view-button",
    attrs: {
      "src": require("../../assets/images/grid_view2.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "button-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Grid")) + " " + _vm._s(_vm.$t("View").toLowerCase()) + " ")])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }